@media only screen and (max-width: 480px) {
    #title_div {
        height: 45px;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 15px;
        padding-bottom: 15px;
    }
    #title_img_div {
        display: block;
        width: 55%
    }
    .title_icons {
        width: 20px;
    }
    .title_div_bg {
        height: 60px;
    }
}