@media only screen and (max-width: 480px) {
    .homeDiv {
        padding: 15px 10% 50px 10%;
        /*height: 100svh;*/
        width: 100%;
        display: flex;
    }

    .homeLogo {
        height: auto;
        width: 100%;
    }

    .homeButton {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.75rem;
        width: 100%;
        height: 10px;
        /*padding: 15px;*/
        margin-top: 20px;
    }
}
