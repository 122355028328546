/* Modal content */
@media only screen and (max-width: 600px) {
    .modal_content {
        margin: 15% auto;
        width: 80%;
        padding: 10px;
    }
}
@media only screen and (max-width: 480px) {
    #todays_result {
        font-size: 7vw;
    }

    #share_text {
        font-size: 11vw;
    }

    .modal_content h3 {
        font-size: 6vw;
    }

    #howToContent {
        margin: 5% auto;
        width: 90%;
    }

    .modal_content p, i {
        font-size: 1em;
    }

    #error_msg {
        font-size: 5vw;
    }
}