/* Sidenav */
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 32px;
}

.sidenav a {
    width: 250px;
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    width: 30px;
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.closebtn {
    padding: 0 !important;
    margin: 0 !important;
    position: relative !important;
}

#sidenavLogoDiv {
    display: flex;
    justify-content: space-between;
}

#sidenavLogo {
    cursor: pointer;
    width: 50px;
    margin-left: 32px;
    padding-bottom: 25px;
}

.socialSidenav {
    position: absolute;
    bottom: 35px;
    left: 25px;
    width: 100px;
    display: flex;
    justify-content: space-between;
}

.socialSidenavIcon {
    cursor: pointer;
    width: 50px;
    transition: 0.3s;
}
.socialSidenavIcon:hover {
    transition: 0.3s;
    filter: invert(50%);
}