@media only screen and (max-width: 480px) {
    button:hover {
        filter: brightness(100%);
    }
    button:active {
        filter: brightness(50%);
        transition: 0.5s;
    }

    .innerPageContainer {
        height: calc(100vh - 60px);
        top: 60px;
    }

    /*html, body {*/
    /*    overflow: hidden;*/
    /*}*/

    /* width */
    ::-webkit-scrollbar {
        width: 3px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: rgba(24, 24, 24, 0.25);
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(136, 136, 136, 0.25);
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(85, 85, 85, 0.5);
    }

    .floatingchat-container-wrap-mobi {
        right: 175px !important;
        left: auto !important;
    }

    .floating-chat-kofi-popup-iframe-mobi {
        left: auto !important;
        right: 10px !important;
    }
}