@media only screen and (max-width: 480px) {
    .gameDiv {
        /*height: calc(100vh - 60px);*/
        /*overflow: auto;*/
        padding-bottom: 75px;
        /*overflow-y: scroll;*/
        /*-webkit-overflow-scrolling: touch;*/
    }

    .autocomplete {
        width: 92%
    }

    div {
        font-size: 5vw;
    }

    button {
        font-size: 4.5vw;
    }

    #button_div {
    }
    #button_div {
        display: flex;
        text-align: center;
        justify-content: space-evenly;
        padding: 0 0%;
    }
    #button_div button {
        width: auto;
    }
    .nextTileButton {
        margin-right: 0;
    }

    #results_text {
        padding: 0;
        font-size: 6vw;
    }

    #albumInput {
        font-size: 5vw;
    }

    #album_result {
        font-size: 25px;
        line-height: 30px;
    }

    #artist_result {
        font-size: 20px;
    }

    #answersTable {
        font-family: 'Futura Regular', sans-serif;
        font-size: 5vw;
    }
    .flip_card {
        height: 350px;
        transform: translate(0, 0) scale(1);
    }

    .flip_card_front, .flip_card_back {
        width: 100%;
        height: 100%;
    }

    .flip_card_back {
        background-clip: content-box;
        /*padding: 5% 2%;*/
        width: 300px;
        height: 300px;
        position: relative;
        margin: auto;
        top: 25px;
        padding: 0;
    }

    /* Modal content */
    .modal_content {
        margin: 15% auto;
        width: 80%;
        padding: 10px;
        top: 25px;
        position: relative;
    }

    #todays_result {
        font-size: 7vw;
    }

    #share_text {
        font-size: 11vw;
    }

    .li_result {
        font-size: 17px;
    }

    .back {
        display: inline;
        float: left;
        margin-left: 10px;
        font-size: 6vw
    }

    ol {
        margin: 25px 0 0 0;
    }

    #completionDiv {
        transform: translate(0px, 0px);
    }

    #track_list_result_container {
        margin-top: -10px
    }

    #error_msg {
        font-size: 5vw;
    }

    .flip_card {
        height: 350px;
    }

    .modal_content h3 {
        font-size: 6vw;
    }

    #howToContent {
        margin: 5% auto;
        width: 90%;
    }

    .modal_content p, i {
        font-size: 1em;
    }

    #loading_anim {
        width: 160px;
    }

    #countdown_text {
        font-size: 1.3rem;
    }
}
