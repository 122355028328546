.dark {
    background-color: #1d1d1d;
    transition: 0.5s;
}
.light {
    background-color: lightgrey;
    transition: 0.5s;
}

.darkText {
    color: white;
}
.lightText {
    color: black;
}

.lightHomeButton {
    background-color: #ffffff;
    color: #000000;
}
.darkHomeButton {
    background-color: #424242;
    color: white;
}

.darkButton {
    color: white;
    background-color: #0f0f0f;
}
.lightButton {
    color: black;
    background-color: #eeeeee;
}

.darkSubmitButton, .lightSubmitButton {
    color: white;
    background-color: #4fc147;
}

.darkInvert {
    filter: invert(1);
    transition: 0.5s;
}
.lightInvert {
    filter: invert(0);
    transition: 0.5s;
}

.darkLink {
    color: cornflowerblue;
}
.lightLink {
    color: blue;
}

.darkModal {
    background-color: black;
}
.lightModal {
    background-color: #fefefe;
}