@font-face {
    font-family: Futura Book;
    src: url("FuturaBook.ttf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: Futura Regular;
    src: url("FuturaPTMedium.otf");
}
@font-face {
    font-family: Futura Bold;
    src: url("FuturaBold.otf");
    font-weight: bold;
    font-style: normal;
}