.homeDiv {
    /*padding: 15%;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    /*height: fit-content;*/
    width: 100%;
    opacity: 0;
}

.homeButtonContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
}

.homeLogo {
    height: 37vh;
    width: auto;
    /*max-width: 500px;*/
}

.homeButton {
    /*font-size: 5vh;*/
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 20px;
    width: 37vh;
    /*max-width: 500px;*/
    /*height: 25px;*/
    padding: 50px 50px 55px;
    max-height: 120px;
}

.homeButton:hover {
    filter: brightness(80%);
    transition: 0.5s;
}

.homeAuthor {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    color: #4d4d4d;
    margin-top: 25px;
    font-size: 18px;
}


@property --bg-angle {
    inherits: false;
    initial-value: 0deg;
    syntax: "<angle>";
}
@keyframes spin {
    to {
        --bg-angle: 360deg;
    }
}
.darkrecapHomeButton {
    animation: spin 2.5s infinite linear;
    background:
            linear-gradient(
                    to bottom,
                    oklch(0.379 0 89.876),
                    oklch(0.379 0 89.876)
            )
            padding-box,
            conic-gradient(
                    from var(--bg-angle) in oklch longer hue,
                    oklch(0.85 0.37 0) 0 0
            )
            border-box;

    border: 5px solid transparent;
}
.lightrecapHomeButton {
    animation: spin 2.5s infinite linear;
    background:
            linear-gradient(
                    to bottom,
                    oklch(1 0 89.876),
                    oklch(1 0 89.876)
            )
            padding-box,
            conic-gradient(
                    from var(--bg-angle) in oklch longer hue,
                    oklch(0.85 0.37 0) 0 0
            )
            border-box;

    border: 5px solid transparent;
}