@media only screen and (max-width: 480px) {
    .support {
        margin: 10px 15px 25px 15px;
        width: auto;
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 2rem;
    }

    .TDLogo {
        height: 250px;
        margin-bottom: 25px;
    }
}