/* Title */
#title_div {
    position: fixed;
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 1;
}
#title_img_div {
    display: inline-flex;
    cursor: pointer;
    width: 20%;
    touch-action: manipulation;
    justify-content: center;
    z-index: 2;
}
#title_img {
    pointer-events: none;
    /*width: 20%;*/
}
.filter-red {
    filter: invert(13%) sepia(99%) saturate(7288%) hue-rotate(3deg) brightness(96%) contrast(112%);
}

.title_icons {
    width: 25px;
    cursor: pointer;
    transition: filter 0.5s;
    touch-action: manipulation;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
.title_icons:active {
    filter: brightness(5);
    transition: filter 0.5s;
}

.title_div_bg {
    /*filter: opacity(50%);*/
    top: 0px;
    width: 100vw;
    position: fixed;
    height: 75px;
    z-index: -1;
    transition: background-color 0.5s;
}
.title_container {
    z-index: 1;
    width: 100%;
    height: 60px;
    transition: filter 0.5s;
}