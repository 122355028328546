.support {
    margin: 15px auto 25px auto;
    width: 60%;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 2rem;
    opacity: 0;
}

.TDLogo {
    height: 400px;
    margin-bottom: 50px;
}

.mainBody {
    background-color: rgba(179, 22, 22, 0) !important;
}