/* Cookie banner */
.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2147483645;
    box-sizing: border-box;
    width: 100%;
    visibility: hidden;
    background-color: #F1F6F4;
}

.cookie-consent-banner__inner {
    max-width: 960px;
    margin: 0 auto;
    padding: 25px 15px;
}

.cookie-consent-banner__copy {
    margin-bottom: 16px;
}

.cookie-consent-banner__actions {
    display: flex;
}

.cookie-consent-banner__header {
    margin-bottom: 8px;

    font-family: "CeraPRO-Bold", sans-serif, arial;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.cookie-consent-banner__description {
    font-family: "CeraPRO-Regular", sans-serif, arial;
    font-weight: normal;
    color: #838F93;
    font-size: 16px;
    line-height: 24px;
}

.cookie-consent-banner__cta {
    box-sizing: border-box;
    display: inline-block;
    width: 47%;
    padding: 11px 13px;

    border-radius: 2px;

    background-color: #2CE080;

    color: #FFF;
    text-decoration: none;
    text-align: center;
    font-family: "CeraPRO-Regular", sans-serif, arial;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
}

#cookie_accept_btn {
    color: white !important;
}

.cookie-consent-banner__cta--secondary {
    padding: 9px 13px;

    border: 2px solid #3A4649;

    background-color: transparent;

    color: #2CE080;
}

.cookie-consent-banner__cta:hover {
    background-color: #20BA68;
}

.cookie-consent-banner__cta--secondary:hover {
    border-color: #838F93;

    background-color: transparent;

    color: #22C870;
}

.cookie-consent-banner__cta:last-child {
    margin-left: 16px;
}

#cookie-bg {
    visibility: hidden;
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 50%;
    background-color: black;
    z-index: 4;
}