/* Modal */
/* Background */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 3; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    cursor: pointer;
}

/* Modal Content/Box */
.modal_content {
    cursor: auto;
    margin: 2% auto; /* 15% from the top and centered */
    padding: 10px 30px;
    border: 1px solid #888;
    width: 50svw; /* Could be more or less, depending on screen size */
    animation-name: animatetop;
    animation-duration: 0.4s
}

/* How to modal content */
#howToModal {
    z-index: 3;
}
#revealGif {
    width: 100%;
}
.modal_content h3 {
    font-size: 2em;
    font-family: 'Futura Bold', sans-serif;
    text-align: center;
    margin: 0;
}

#howToContent {
    width: min(600px, 90%);
    height: fit-content;
}
.modal_content p {
    font-size: 1.7em;
}
.closebtn {
    padding: 0 !important;
    margin: 0 !important;
    position: relative !important;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

#todays_result {
    font-size: 45px;
    font-family: 'Futura Bold', sans-serif;
    text-align: center;
    padding-top: 15px
}

#next_button {
    margin-top: 20px;
}

.shareModalButton {
    width: 75%;
}