.flip_card_back_download {
    text-align: center;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    background-clip: content-box;
    background-color: black;
    color: white;
    width: 400px;
    aspect-ratio: 1/1;
    position: relative;
    margin: auto;
    padding: 0;
}
.li_result_download {
    font-size: 20px
}

#album_result_download {
    font-size: 60px;
    letter-spacing: -6px;
    padding-top: 15px;
    padding-bottom: 5px;
}
#artist_result_download {
    padding-top: 10px;
    font-size: 30px;
}