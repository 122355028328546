.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  -webkit-tap-highlight-color: transparent;
}

.App {
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  /*height: 100vh;*/
}

.pageContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  /*justify-content: center;*/
  flex-direction: column;
}
.innerPageContainer {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  width: 100%;
  height: calc(100vh - 75px);
  position: absolute;
  /*padding-bottom: 75px;*/
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
  top: 75px;
}

div {
  font-family: "Futura Book", sans-serif;
}
strong {
  font-family: Futura Bold, sans-serif;
  font-weight: bold;
}


ol, li {
  list-style: decimal outside;
}

button {
  cursor: pointer;
  border-radius: 5px;
  padding: 40px;
  font-family: "Futura Bold", sans-serif;
  color: black;
  border: 0;
  flex-basis: 45%;
  touch-action: manipulation;
  font-size: 23px;
  filter: brightness(100%);
  transition: 0.3s;
}

button:active {
  filter: brightness(50%);
  transition: 0.3s;
}

button:hover {
  filter: brightness(75%);
  transition: 0.3s;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #181818;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.floatingchat-container-wrap, .floating-chat-kofi-popup-iframe {
  left: auto !important;
  right: 16px !important;
}