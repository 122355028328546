.gameDiv {
    height: 100%;
    opacity: 0%;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip_card {
    height: 700px;
    transform: translate(0, -10%) scale(0.75);
    width: 100%;
    perspective: 1500px;
}

/* This container is needed to position the front and back side */
.flip_card_inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

/* Position the front and back side */
.flip_card_front, .flip_card_back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip_card_front {
    color: black;
}

/* Style the back side */
.flip_card_back {
    background-clip: content-box;
    background-color: black;
    color: white;
    transform: rotateY(180deg);
    width: 650px;
    height: 650px;
    position: relative;
    margin: auto;
    top: 25px;
    padding: 0;
}

.album_result, .artist_result {
    margin: 0;
    padding: 0;
}

.album_result {
    font-family: "Futura Bold", sans-serif;
    font-size: 40px;
    padding-top: 10px;
    line-height: 50px;
}

.artist_result {
    padding-top: 5px;
    font-size: 35px;
}

/* The Close Button */
.back {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.back:hover,
.back:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.li_result {
    font-size: 25px;
}
::marker {
    --markerColor: 'black';
    color: var(--markerColor);
}

.back {
    display: inline;
    float: left;
    margin-left: 10px;
    font-size: 23px
}

ol {
    margin: 10px 0 0 0;
}

#track_list_result_container {
    width: 90%;
    display: flex;
    justify-content: space-around;
    margin-top: 5%
}

#error_msg {
    font-size: 23px;
    font-family: 'Futura Regular', sans-serif
}

#loading_div {
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    z-index: 1;
    justify-content: center;
    align-items: center;
}
#loading_anim {
    width: 330px;
}

#button_div {
    /*display: flex;*/
    text-align: center;
    justify-content: space-evenly;
    margin-top: 20px;
    /*padding: 0 30%;*/
}
#button_div button {
    width: 240px;
}
.nextTileButton {
    margin-right: 6px;
}

input {
    border: 1px solid transparent;
    background-color: #f1f1f1;
    padding: 20px;
    font-size: 16px;
}
input[type=text] {
    background-color: #f1f1f1;
    width: 100%;
}
input[type=submit] {
    background-color: DodgerBlue;
    color: #fff;
}

#completionDiv {
    transform: translate(0px, -125px);
}
.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
    width: 490px;
    z-index: 3;
}
.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
}
.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}
.autocomplete-items div:last-child {
    margin-bottom: 50px;
    border-radius: 0px 0px 25px 25px;
}
.autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
}
.autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
    transition: 1s;
}

/* Results table*/
#results_text {
    padding: 20px 20px 0 20px;
    margin-top: 0;
    margin-bottom: 5px;
    font-family: 'Futura Regular', sans-serif;
    font-size: 28px;
}

table {
    width: 92%;
    border-radius: 5px;
}
table td {
    padding-top: 10px;
    padding-bottom: 10px;
}
/* Completion area: album input, answers table */
#albumInput {
    font-family: "Futura Book", sans-serif;
    font-size: 23px;
    border-radius: 25px;
    border: 0px solid black;
    transition: 0.1s;
}
#albumInput:focus {
    transition: 0.1s;
    outline: none;
    border: 2px solid #7a7a7a;
}
#answersTable {
    font-family: 'Futura Regular', sans-serif;
    font-size: 23px;
}
#answer {
    font-size: 5.5vw;
    font-family: "Futura Regular", sans-serif;
    font-weight: 500;
    margin-bottom: 0;
}
#incorrect_div {
    padding: 25%;
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0;
    margin-top: 24%;
}
button {
    border-radius: 25px;
    padding: 30px;
    font-family: "Futura Bold", sans-serif;
    color: black;
    border: 0;
    flex-basis: 45%;
    touch-action: manipulation;
    font-size: 23px;
}

img {
    margin: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

canvas {
    display: block;
    width: 100%;
    height: 100%;
    transition: 1s;
}

* { box-sizing: border-box; }

#countdown_text {
    font-size: 1.5rem;
    margin-bottom: 15px;
}