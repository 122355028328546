@media only screen and (max-width: 600px) {
    .flip_card_back_download {
        top: 0;
        width: 210px;
    }
    #album_result_download {
        font-size: 40px;
        letter-spacing: -6px;
        padding-top: 5px;
        padding-bottom: 0px;
    }
    #artist_result_download {
        padding-top: 0px;
        font-size: 15px;
    }
    .li_result_download {
        font-size: 10px
    }
}